// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"aabfba6ed60361bd353e0f63db2d26b07d4258b2"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import auth from '@/config/auth';
import { z } from 'zod';

function getUserContext() {
  if (typeof document === 'undefined') {
    return null;
  }

  const cookies = document.cookie.split(';');
  const userContextCookie = cookies
    .find((cookie) =>
      cookie.trim().startsWith(`${auth.session.userContextCookieName}=`)
    )
    ?.split('=')[1];

  if (!userContextCookie) {
    return null;
  }

  try {
    const userContext = z
      .object({
        id: z.string(),
        email: z.string().nullable(),
      })
      .parse(JSON.parse(decodeURIComponent(userContextCookie)));
    return userContext;
  } catch (error) {
    return null;
  }
}

Sentry.init({
  // to be implemented when dynamic environments are available
  // environment: 'development',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Add optional integrations for additional features
  integrations: [],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend: async (event) => {
    const userContext = getUserContext();
    if (userContext) {
      event.user = {
        ...event.user,
        id: userContext.id,
        email: userContext.email || undefined,
      };
    }
    return event;
  },
});
