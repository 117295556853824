import ms from 'ms';
import { getEnvVariable } from './environment';

const auth = {
  session: {
    cookieName: 'opened.session-token',
    userContextCookieName: 'opened.user-context',
    impersonationOriginalUrlCookieName: 'opened.impersonation-original-url',
    maxAgeSeconds: ms('30d') / 1000,
    maxAgeMs: ms('30d'),
    impersonatedMaxAgeMs: ms('10m'),
  },
  legacy: {
    get infocenterSalt() {
      return process.env.LEGACY_INFOCENTER_SALT ?? 'asin';
    },
  },
  providers: {
    google: {
      get clientId() {
        return getEnvVariable('AUTH_GOOGLE_CLIENT_ID');
      },
      get clientSecret() {
        return getEnvVariable('AUTH_GOOGLE_CLIENT_SECRET');
      },
    },
    facebook: {
      get clientId() {
        return getEnvVariable('FACEBOOK_CLIENT_ID');
      },
      get clientSecret() {
        return getEnvVariable('FACEBOOK_CLIENT_SECRET');
      },
    },
    amazon: {
      get clientId() {
        return getEnvVariable('AMAZON_CLIENT_ID');
      },
      get clientSecret() {
        return getEnvVariable('AMAZON_CLIENT_SECRET');
      },
    },
  },
  get loginTokenSecret() {
    return getEnvVariable('LOGIN_TOKEN_SECRET');
  },
};

export default auth;
